import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

export const getImageUploadUrl = createAsyncThunk(
  'perks/getImageUploadUrl',
  async ({ fileName, fileType }, { rejectWithValue }) => {
    try {
      const response = await api.post('/perks/get-upload-url', { fileName, fileType });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to get upload URL');
    }
  }
);

// Existing thunks
export const fetchUserPerks = createAsyncThunk(
  'perks/fetchUserPerks',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/perks/user');
      console.log('Fetched user perks:', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch user perks');
    }
  }
);

export const fetchPerks = createAsyncThunk(
  'perks/fetchPerks',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/perks');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPerk = createAsyncThunk(
  'perks/createPerk',
  async (perkData, { rejectWithValue }) => {
    try {
      const response = await api.post('/perks', perkData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePerk = createAsyncThunk(
  'perks/updatePerk',
  async ({ perkId, updateData }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/perks/${perkId}`, updateData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update perk');
    }
  }
);

export const deletePerk = createAsyncThunk(
  'perks/deletePerk',
  async (perkId, { rejectWithValue }) => {
    try {
      await api.delete(`/perks/${perkId}`);
      return perkId;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete perk');
    }
  }
);

const perkSlice = createSlice({
  name: 'perks',
  initialState: {
    items: [],
    userPerks: [],
    loading: false,
    error: null,
    uploadStatus: 'idle', // Add upload status tracking
  },
  reducers: {},
  extraReducers: (builder) => {
    // Add image upload URL handling
    builder
      .addCase(getImageUploadUrl.pending, (state) => {
        state.uploadStatus = 'loading';
      })
      .addCase(getImageUploadUrl.fulfilled, (state) => {
        state.uploadStatus = 'succeeded';
      })
      .addCase(getImageUploadUrl.rejected, (state, action) => {
        state.uploadStatus = 'failed';
        state.error = action.payload;
      })

    // Existing reducers
    builder
      .addCase(fetchUserPerks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserPerks.fulfilled, (state, action) => {
        state.loading = false;
        state.userPerks = action.payload;
      })
      .addCase(fetchUserPerks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPerks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPerks.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchPerks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPerk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPerk.fulfilled, (state, action) => {
        state.loading = false;
        state.items.push(action.payload);
        state.userPerks.push(action.payload);
      })
      .addCase(createPerk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updatePerk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePerk.fulfilled, (state, action) => {
        state.loading = false;
        state.items = state.items.map(perk => 
          perk._id === action.payload._id ? action.payload : perk
        );
        state.userPerks = state.userPerks.map(perk => 
          perk._id === action.payload._id ? action.payload : perk
        );
      })
      .addCase(updatePerk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePerk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePerk.fulfilled, (state, action) => {
        state.loading = false;
        state.items = state.items.filter(perk => perk._id !== action.payload);
        state.userPerks = state.userPerks.filter(perk => perk._id !== action.payload);
      })
      .addCase(deletePerk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default perkSlice.reducer;