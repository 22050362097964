import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUserProfile, updateUserProfile, deleteAccount } from '../redux/slices/authSlice';
import { showToast } from '../utils/toast';
import { Card, CardHeader, CardContent, CardTitle, CardDescription, CardFooter } from './ui/card';
import { 
  User, 
  Building2, 
  MapPin, 
  Briefcase, 
  Mail, 
  Link as LinkIcon, 
  Edit2, 
  Save, 
  X, 
  Copy,
  Eye,
  EyeOff,
  Trash2
} from 'lucide-react';

const DeleteAccountModal = ({ isOpen, onClose, onConfirm }) => {
  const [confirmText, setConfirmText] = useState('');
  
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <h3 className="text-xl font-bold text-red-600 mb-4">Delete Account</h3>
        <p className="text-gray-700 mb-4">
          This action is permanent and cannot be undone. All your data, including perks, bids, and exchanges will be deleted.
        </p>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Type "DELETE" to confirm
          </label>
          <input
            type="text"
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-red-500"
            placeholder="Type DELETE here"
          />
        </div>
        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={confirmText !== 'DELETE'}
            className={`px-4 py-2 text-sm font-medium text-white rounded-md ${
              confirmText === 'DELETE'
                ? 'bg-red-600 hover:bg-red-700'
                : 'bg-gray-300 cursor-not-allowed'
            }`}
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { user, loading, error } = useSelector((state) => state.auth);
  
  const [formData, setFormData] = useState({
    name: '',
    city: '',
    company: '',
    jobTitle: '',
    linkedin: '',
    discoverability: 'public'
  });

  useEffect(() => {
    if (!user) {
      dispatch(fetchUserProfile());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        city: user.city || '',
        company: user.company || '',
        jobTitle: user.jobTitle || '',
        linkedin: user.linkedin || '',
        discoverability: user.discoverability || 'public'
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedData = {
        ...formData,
        generateNewInviteCode: formData.discoverability === 'private' && !user.inviteCode
      };

      await showToast.promise(
        dispatch(updateUserProfile(updatedData)).unwrap(),
        {
          loading: 'Updating profile...',
          success: 'Profile updated successfully!',
          error: 'Failed to update profile. Please try again.'
        }
      );
      setIsEditing(false);
      dispatch(fetchUserProfile());
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await showToast.promise(
        dispatch(deleteAccount()).unwrap(),
        {
          loading: 'Deleting account...',
          success: 'Account deleted successfully. Goodbye!',
          error: 'Failed to delete account. Please try again.'
        }
      );
      navigate('/');
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <Card>
          <CardContent className="text-center p-6">
            <p className="text-gray-600">No user data found. Please try logging in again.</p>
          </CardContent>
        </Card>
      </div>
    );
  }

  const ProfileView = () => (
    <>
      <div className="mb-8 flex items-start gap-6">
        <div className="p-2 bg-blue-50 rounded-lg">
          <User className="w-12 h-12 text-blue-500" />
        </div>
        <div>
          <h2 className="text-2xl font-bold text-gray-900">{user.name}</h2>
          <p className="text-gray-600">{user.email}</p>
        </div>
      </div>

      <dl className="grid grid-cols-1 gap-4">
        <div className="flex items-center gap-3">
          <Building2 className="w-5 h-5 text-gray-500" />
          <div>
            <dt className="text-sm font-medium text-gray-500">Company</dt>
            <dd className="text-gray-900">{user.company || 'Not specified'}</dd>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <MapPin className="w-5 h-5 text-gray-500" />
          <div>
            <dt className="text-sm font-medium text-gray-500">City</dt>
            <dd className="text-gray-900">{user.city || 'Not specified'}</dd>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <Briefcase className="w-5 h-5 text-gray-500" />
          <div>
            <dt className="text-sm font-medium text-gray-500">Job Title</dt>
            <dd className="text-gray-900">{user.jobTitle || 'Not specified'}</dd>
          </div>
        </div>

        {user.linkedin && (
          <div className="flex items-center gap-3">
            <LinkIcon className="w-5 h-5 text-gray-500" />
            <div>
              <dt className="text-sm font-medium text-gray-500">LinkedIn</dt>
              <dd>
                <a 
                  href={user.linkedin} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  View Profile
                </a>
              </dd>
            </div>
          </div>
        )}

        <div className="flex items-center gap-3">
          {user.discoverability === 'private' ? (
            <EyeOff className="w-5 h-5 text-gray-500" />
          ) : (
            <Eye className="w-5 h-5 text-gray-500" />
          )}
          <div>
            <dt className="text-sm font-medium text-gray-500">Profile Visibility</dt>
            <dd className="text-gray-900">
              {user.discoverability === 'private' ? 'Private' : 'Public'}
            </dd>
          </div>
        </div>

        {user.discoverability === 'private' && user.inviteCode && (
          <div className="flex items-center gap-3">
            <Mail className="w-5 h-5 text-gray-500" />
            <div>
              <dt className="text-sm font-medium text-gray-500">Invite Code</dt>
              <dd className="flex items-center gap-2">
                <code className="px-2 py-1 bg-gray-100 rounded font-mono">
                  {user.inviteCode}
                </code>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(user.inviteCode);
                    showToast.success('Invite code copied!');
                  }}
                  className="p-1 text-gray-500 hover:text-gray-700"
                >
                  <Copy className="w-4 h-4" />
                </button>
              </dd>
            </div>
          </div>
        )}
      </dl>
    </>
  );

  const ProfileForm = () => (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Full Name
        </label>
        <input
          name="name"
          type="text"
          value={formData.name}
          onChange={handleChange}
          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Company
        </label>
        <input
          name="company"
          type="text"
          value={formData.company}
          onChange={handleChange}
          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          City
        </label>
        <input
          name="city"
          type="text"
          value={formData.city}
          onChange={handleChange}
          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Job Title
        </label>
        <input
          name="jobTitle"
          type="text"
          value={formData.jobTitle}
          onChange={handleChange}
          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          LinkedIn URL
        </label>
        <input
          name="linkedin"
          type="url"
          value={formData.linkedin}
          onChange={handleChange}
          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Profile Visibility
        </label>
        <select
          name="discoverability"
          value={formData.discoverability}
          onChange={handleChange}
          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        >
          <option value="public">Public - Anyone can find my profile</option>
          <option value="private">Private - Only accessible with invite code</option>
        </select>
        <p className="mt-1 text-sm text-gray-500">
          {formData.discoverability === 'private' ? 
            'A new invite code will be generated for your private profile' :
            'Your profile will be visible to everyone in the marketplace'
          }
        </p>
      </div>
    </form>
  );

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>Profile</CardTitle>
            {!isEditing && (
              <div className="flex gap-2">
                <button
                  onClick={() => setIsEditing(true)}
                  className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                >
                  <Edit2 className="w-4 h-4 mr-2" />
                  Edit Profile
                </button>
                <button
                  onClick={() => setIsDeleteModalOpen(true)}
                  className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
                >
                  <Trash2 className="w-4 h-4 mr-2" />
                  Delete Account
                </button>
              </div>
            )}
          </div>
        </CardHeader>

        <CardContent>
          {isEditing ? <ProfileForm /> : <ProfileView />}
        </CardContent>

        {isEditing && (
          <CardFooter className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              <X className="w-4 h-4 mr-2" />
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
            >
              <Save className="w-4 h-4 mr-2" />
              Save Changes
            </button>
          </CardFooter>
        )}
      </Card>

     <DeleteAccountModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteAccount}
      />
    </div>
  );
};

export default Profile;
