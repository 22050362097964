import React, { useState, useEffect } from 'react';

const PrivacyPolicyModal = ({ isOpen, onClose }) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setScrolledToBottom(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight * 1.2) {
      setScrolledToBottom(true);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] flex flex-col">
        <div className="p-6 border-b">
          <h2 className="text-2xl font-semibold text-gray-900">Privacy Policy for Perk Exchange</h2>
        </div>

        <div className="p-6 overflow-y-auto flex-grow" onScroll={handleScroll}>
          <div className="prose prose-blue max-w-none space-y-6">
            <p className="text-sm text-gray-600">Effective Date: March 14, 2025</p>
            
            <p className="text-gray-800">At Perk Exchange, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Perk Exchange platform (the "Platform"). Please read this Privacy Policy carefully. By using the Platform, you consent to the data practices described in this policy.</p>
            
            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">1. Information We Collect</h3>
              <p>We may collect information about you in various ways, including:</p>
              <h4 className="text-base font-medium mt-4 mb-2">Personal Data</h4>
              <p>Personal Data is information that can be used to identify you personally. We may collect the following Personal Data:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>Name</li>
                <li>Email address</li>
                <li>Employer/company name</li>
                <li>Job title</li>
                <li>Profile information</li>
                <li>Information about the perks you offer for exchange</li>
              </ul>
              
              <h4 className="text-base font-medium mt-4 mb-2">Usage Data</h4>
              <p>We may also collect information about how you access and use the Platform, including:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>IP address</li>
                <li>Browser type</li>
                <li>Pages visited</li>
                <li>Time and date of your visit</li>
                <li>Time spent on pages</li>
                <li>Device information</li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">2. How We Use Your Information</h3>
              <p>We may use the information we collect about you for various purposes, including to:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>Provide, maintain, and improve the Platform</li>
                <li>Process and complete perk exchange transactions</li>
                <li>Verify your identity and eligibility to use the Platform</li>
                <li>Communicate with you about your account, updates, and Platform features</li>
                <li>Monitor and analyze usage patterns and trends</li>
                <li>Protect against, identify, and prevent fraud and other illegal activity</li>
                <li>Comply with our legal obligations</li>
                <li>Enforce our Terms and Conditions</li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">3. Sharing Your Information</h3>
              <p>We may share your information in the following circumstances:</p>
              <ul className="list-disc pl-5 mt-2">
                <li><strong>With Other Users:</strong> When you engage in perk exchanges, we share necessary information with the other party to facilitate the transaction.</li>
                <li><strong>With Service Providers:</strong> We may share your information with third-party vendors who provide services on our behalf, such as hosting, data analysis, and customer service.</li>
                <li><strong>For Legal Purposes:</strong> We may disclose your information if required by law or in the good-faith belief that such action is necessary to comply with legal obligations or protect our rights or safety.</li>
                <li><strong>Business Transfers:</strong> If Perk Exchange is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
              </ul>
              <p>We do not sell your personal information to third parties for marketing purposes.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">4. Data Security</h3>
              <p>We have implemented appropriate security measures to protect the security of your personal information. However, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee the security of your data transmission to our Platform.</p>
              <p>You are responsible for maintaining the confidentiality of your account credentials and for restricting access to your computer or device. If you believe your account has been compromised, please contact us immediately.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">5. Your Privacy Rights</h3>
              <p>Depending on your location, you may have certain rights regarding your personal information, including:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>The right to access and review your personal information</li>
                <li>The right to correct inaccurate personal information</li>
                <li>The right to request deletion of your personal information</li>
                <li>The right to restrict or object to processing of your personal information</li>
                <li>The right to data portability</li>
              </ul>
              <p>To exercise these rights, please contact us using the information provided at the end of this Privacy Policy.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">6. Cookies and Tracking Technologies</h3>
              <p>We use cookies and similar tracking technologies to track activity on our Platform and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier.</p>
              <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Platform.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">7. Children's Privacy</h3>
              <p>Our Platform is not intended for use by children under the age of 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete such information.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">8. Changes to This Privacy Policy</h3>
              <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top.</p>
              <p>We recommend that you review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">9. Contact Us</h3>
              <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
              <p>Email: privacy@perk-exchange.com</p>
              <p>Address: 500 N. Brand Blvd. Suite 890 Glendale CA 91203</p>
            </div>
          </div>
        </div>

        <div className="p-6 border-t bg-gray-50 space-y-4">
          <div className="flex justify-end gap-4 pt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;