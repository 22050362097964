// frontend/src/components/Auth.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { login, register } from '../redux/slices/authSlice';
import TermsModal from './TermsModal';
import { showToast } from '../utils/toast';
import { trackSignUp, trackBid, trackExchangeComplete } from '../utils/analytics';
import '../styles/Auth.css';

const Auth = ({ onBack }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, error, loading } = useSelector((state) => state.auth);

  const [isLogin, setIsLogin] = useState(location.pathname === '/login');
  const [showTerms, setShowTerms] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [needsVerification, setNeedsVerification] = useState(false);
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [signupData, setSignupData] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    city: '',
    jobTitle: '',
    linkedin: '',
    company: ''
  });

  // Add this effect to handle redirection when authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/marketplace');
    }
  }, [isAuthenticated, navigate]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      await showToast.promise(
        dispatch(login(loginData)).unwrap(),
        {
          loading: 'Signing in...',
          success: 'Welcome back!',
          error: (err) => {
            if (err.needsVerification) {
              setNeedsVerification(true);
              return 'Please verify your email before logging in';
            }
            return `Login failed: ${err.message}`;
          }
        }
      );
    } catch (err) {
      console.error('Login failed:', err);
      if (err.needsVerification) {
        setNeedsVerification(true);
      }
    }
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    setShowTerms(true);
  };

  const handleTermsAccept = async () => {
  try {
    // Clean up the signup data before submission
    const dataToSubmit = { ...signupData };
    
    // Only remove empty strings, but allow any non-empty string for LinkedIn
    if (!dataToSubmit.linkedin || dataToSubmit.linkedin.trim() === '') {
      dataToSubmit.linkedin = undefined; // This will be excluded from JSON
    }
    
    await dispatch(register(dataToSubmit)).unwrap();
    // Track successful sign up
    trackSignUp();

    setShowTerms(false);
    navigate('/registration-success');
  } catch (err) {
    console.error('Signup failed:', err);
    showToast.error(err.message || 'Registration failed');
    setShowTerms(false);
  }
};

  const handleLoginChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleSignupChange = (e) => {
    setSignupData({ ...signupData, [e.target.name]: e.target.value });
  };


  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      {onBack && (
        <button 
          onClick={onBack}
          className="absolute top-4 left-4 text-gray-600 hover:text-gray-900 flex items-center gap-2"
        >
          <span>←</span> Back to Home
        </button>
      )}

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {isLogin ? 'Welcome back' : 'Create your account'}
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          {isLogin ? (
            <>
              New to PerkExchange?{' '}
              <button
                onClick={() => setIsLogin(false)}
                className="font-medium text-blue-600 hover:text-blue-500"
              >
                Create an account
              </button>
            </>
          ) : (
            <>
              Already have an account?{' '}
              <button
                onClick={() => setIsLogin(true)}
                className="font-medium text-blue-600 hover:text-blue-500"
              >
                Sign in
              </button>
            </>
          )}
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <div className="mb-4 p-4 rounded-md bg-red-50 border border-red-200">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          )}

          {isLogin ? (
            <form onSubmit={handleLoginSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  required
                  value={loginData.email}
                  onChange={handleLoginChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  name="password"
                  required
                  value={loginData.password}
                  onChange={handleLoginChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="flex flex-col space-y-4">
                <button
                  type="submit"
                  disabled={loading}
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                    loading
                      ? 'bg-blue-400 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  }`}
                >
                  {loading ? 'Signing in...' : 'Sign in'}
                </button>
                <Link
                  to="/reset-password"
                  className="text-center text-sm text-blue-600 hover:text-blue-700"
                >
                  Forgot your password?
                </Link>
              </div>
            </form>
          ) : (
            <form onSubmit={handleSignupSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Full Name
                </label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  required
                  value={signupData.name}
                  onChange={handleSignupChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                  Username
                </label>
                <input
                  id="username"
                  type="text"
                  name="username"
                  required
                  value={signupData.username}
                  onChange={handleSignupChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label htmlFor="signup-email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  id="signup-email"
                  type="email"
                  name="email"
                  required
                  value={signupData.email}
                  onChange={handleSignupChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label htmlFor="signup-password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  id="signup-password"
                  type="password"
                  name="password"
                  required
                  value={signupData.password}
                  onChange={handleSignupChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                  Company
                </label>
                <input
                  id="company"
                  type="text"
                  name="company"
                  value={signupData.company}
                  onChange={handleSignupChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  City
                </label>
                <input
                  id="city"
                  type="text"
                  name="city"
                  value={signupData.city}
                  onChange={handleSignupChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700">
                  Job Title
                </label>
                <input
                  id="jobTitle"
                  type="text"
                  name="jobTitle"
                  value={signupData.jobTitle}
                  onChange={handleSignupChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label htmlFor="linkedin" className="block text-sm font-medium text-gray-700">
                  LinkedIn URL (Optional)
                </label>
                <input
                  id="linkedin"
                  type="text"
                  name="linkedin"
                  value={signupData.linkedin}
                  onChange={handleSignupChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label htmlFor="discoverability" className="block text-sm font-medium text-gray-700">
                  Profile Visibility
                </label>
                <select
                  id="discoverability"
                  name="discoverability"
                  value={signupData.discoverability}
                  onChange={handleSignupChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="public">Public - Anyone can find my profile</option>
                  <option value="private">Private - Only accessible with invite code</option>
                </select>
                <p className="mt-1 text-sm text-gray-500">
                  {signupData.discoverability === 'private' ? 
                    'You will receive an invite code after registration to share with friends' :
                    'Your profile will be visible in the marketplace'
                  }
                </p>
              </div>

              <button
                type="submit"
                disabled={loading}
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                  loading
                    ? 'bg-blue-400 cursor-not-allowed'
                    : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                }`}
              >
                {loading ? 'Creating account...' : 'Create account'}
              </button>
            </form>
          )}

          {registrationSuccess && (
            <div className="mt-8 text-center">
              <div className="bg-green-50 p-4 rounded-lg">
                <h3 className="text-lg font-medium text-green-800">Registration Successful!</h3>
                <p className="mt-2 text-sm text-green-600">
                  Please check your email to verify your account.
                </p>
              </div>
            </div>
          )}

          {needsVerification && (
            <div className="mt-8 text-center">
              <div className="bg-yellow-50 p-4 rounded-lg">
                <h3 className="text-lg font-medium text-yellow-800">Email Verification Required</h3>
                <p className="mt-2 text-sm text-yellow-600">
                  Please verify your email before logging in.
                </p>
                <button
                  onClick={() => navigate('/resend-verification')}
                  className="mt-4 text-sm text-blue-600 hover:underline"
                >
                  Resend verification email
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <TermsModal
        isOpen={showTerms}
        onClose={() => setShowTerms(false)}
        onAccept={handleTermsAccept}
        fullName={signupData.name}
      />
    </div>
  );
};

export default Auth;