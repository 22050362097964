// src/utils/analytics.js
import ReactGA from 'react-ga4';

// Initialize Google Analytics
export const initGA = (trackingId) => {
  ReactGA.initialize(trackingId);
  console.log('Google Analytics initialized with ID:', trackingId);
};

// Track page views
export const trackPageView = (path) => {
  const page = path || window.location.pathname + window.location.search;
  ReactGA.send({ hitType: "pageview", page });
  console.log('Page view tracked:', page);
};

// Track user events
export const trackEvent = (category, action, label = null, value = null) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
  console.log('Event tracked:', { category, action, label, value });
};

// Funnel tracking functions
export const trackSignUp = () => {
  // Push to dataLayer specifically for GTM
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'signup',  // This is what GTM looks for by default
    'eventCategory': 'Funnel',
    'eventAction': 'Sign Up'
  });
  
  // Original GA tracking
  trackEvent('Funnel', 'Sign Up');
};

export const trackAddPerk = () => {
  // Push to dataLayer for GTM
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'add_perk',
    'eventCategory': 'Funnel',
    'eventAction': 'Add Perk'
  });
  
  trackEvent('Funnel', 'Add Perk');
};

export const trackBid = () => {
  // Push to dataLayer for GTM
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'place_bid',
    'eventCategory': 'Funnel',
    'eventAction': 'Place Bid'
  });
  
  trackEvent('Funnel', 'Place Bid');
};

export const trackExchangeComplete = () => {
  // Push to dataLayer for GTM
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'complete_exchange',
    'eventCategory': 'Funnel',
    'eventAction': 'Complete Exchange'
  });
  
  trackEvent('Funnel', 'Complete Exchange');
};