// src/components/AuthProvider.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile, logout } from '../redux/slices/authSlice';

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, user, loading, error } = useSelector((state) => state.auth);
  const [profileFetchAttempted, setProfileFetchAttempted] = useState(false);

  useEffect(() => {
    // Check if we have a token but no user data
    const token = localStorage.getItem('token');
    if (token && !user && !loading && !profileFetchAttempted) {
      setProfileFetchAttempted(true);
      dispatch(fetchUserProfile())
        .unwrap()
        .catch(error => {
          // If the profile endpoint returns 404, just log out the user
          if (error?.status === 404) {
            console.log('User profile not found, logging out');
            localStorage.removeItem('token');
            dispatch(logout());
          }
        });
    }
  }, [dispatch, user, loading, profileFetchAttempted]);

  return <>{children}</>;
};

export default AuthProvider;