// src/App.js
import React, { useState, useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { store } from './redux/store';
import './App.css';
import ErrorBoundary from './components/ErrorBoundary';
import AuthProvider from './components/AuthProvider';

// Components
import LandingPage from './components/LandingPage';
import PublicMarketplace from './components/PublicMarketplace';
import VerifyEmail from './components/VerifyEmail';
import ResendVerification from './components/ResendVerification';
import RegistrationSuccess from './components/RegistrationSuccess';
import ResetPassword from './components/ResetPassword';
import ToastProvider from './components/ToastProvider';
import Auth from './components/Auth';
import Navbar from './components/Navbar';
import Marketplace from './components/Marketplace';
import MyPerks from './components/MyPerks';
import MyBids from './components/MyBids';
import AcceptedBids from './components/AcceptedBids';
import FriendsList from './components/FriendsList';
import Profile from './components/Profile';
import UserProfile from './components/UserProfile';

// Analytics
import { initGA, trackPageView } from './utils/analytics';

// GA Tracking ID - replace with your actual tracking ID
const TRACKING_ID = 'G-K4LW72GTS1';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading, user } = useSelector((state) => state.auth);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!isAuthenticated || !user) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        {children}
      </div>
    </>
  );
};

// Page view tracking component
const PageViewTracker = () => {
  const location = useLocation();
  
  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

const AppContent = () => {
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  return (
    <>
      <PageViewTracker />
      <Routes>
        {/* Public Routes */}
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
        <Route path="/resend-verification" element={<ResendVerification />} />
        <Route path="/registration-success" element={<RegistrationSuccess />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/login" element={<Auth />} />
        <Route path="/register" element={<Auth />} />
        <Route path="/public-marketplace" element={<PublicMarketplace />} />
        
        {/* Protected Routes */}
        <Route 
          path="/marketplace" 
          element={
            <ProtectedRoute>
              <Marketplace />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/myperks" 
          element={
            <ProtectedRoute>
              <MyPerks />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/mybids" 
          element={
            <ProtectedRoute>
              <MyBids />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/acceptedbids" 
          element={
            <ProtectedRoute>
              <AcceptedBids />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/friends" 
          element={
            <ProtectedRoute>
              <FriendsList />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/profile" 
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/profile/:userId" 
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          } 
        />

        {/* Landing Page / Home */}
        <Route 
          path="/" 
          element={
            isAuthenticated ? (
              <ProtectedRoute>
                <Marketplace />
              </ProtectedRoute>
            ) : (
              <LandingPage />
            )
          } 
        />

        {/* Catch all redirect */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics when the app mounts
    initGA(TRACKING_ID);
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Router>
          <AuthProvider>
            <ToastProvider />
            <AppContent />
          </AuthProvider>
        </Router>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;