import React, { useState } from 'react';
import { trackSignUp, trackBid, trackExchangeComplete } from '../utils/analytics';
import '../styles/ExchangeConfirmation.css';

const ExchangeConfirmation = ({ bid, currentUser, onSendProposal, onClose }) => {
  const [completionDate, setCompletionDate] = useState('');
  const [logistics, setLogistics] = useState('');
  
  console.log('Current bid data:', bid);

  const handleSendProposal = () => {
    if (!completionDate) {
      window.alert('Please select a completion date');
      return;
    }

    if (!logistics.trim()) {
      window.alert('Please enter logistics details');
      return;
    }

    // Format the date as an ISO string
    const formattedDate = new Date(completionDate + 'T00:00:00Z').toISOString();

    const proposalData = {
      completionDate: formattedDate,
      logistics: logistics.trim()
    };

    console.log('Sending proposal with:', proposalData);
    
    // Track exchange completion
    trackExchangeComplete();

    onSendProposal(proposalData);
  };

  const isCurrentUserBidder = currentUser._id === bid.bidderId._id;
  const otherUserName = isCurrentUserBidder ? bid.offeredPerkId.userId.name : bid.bidderId.name;

  const transactionDescription = `${isCurrentUserBidder ? "You" : bid.bidderId.name} agree${isCurrentUserBidder ? "" : "s"} to trade ${bid.quantity}x ${bid.bidPerkId.title} for ${isCurrentUserBidder ? "the" : "your"} ${bid.offeredPerkId.title} from ${otherUserName}, to be completed by ${completionDate || "[Date to be set]"}.`;

  // Ensure the minimum date is today
  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="exchange-confirmation">
      <div className="modal-header">
        <h2>Exchange Confirmation</h2>
        <button onClick={onClose} className="close-button">&times;</button>
      </div>

      <div className="form-content">
        <div className="form-group">
          <label>Completion Date:</label>
          <input 
            type="date" 
            value={completionDate} 
            onChange={(e) => setCompletionDate(e.target.value)}
            min={today}
            required
            className="date-input"
          />
        </div>

        <div className="form-group">
          <label>Logistics:</label>
          <textarea
            value={logistics}
            onChange={(e) => setLogistics(e.target.value)}
            placeholder="Enter the logistics of the exchange (e.g., meeting place, time, contact details)..."
            required
            className="logistics-input"
            rows={4}
          />
        </div>

        <div className="transaction-summary">
          <h3>Transaction Summary:</h3>
          <div className="summary-content">
            <p>{transactionDescription}</p>
            <p>Logistics: {logistics || "[To be determined]"}</p>
          </div>
        </div>

        <div className="modal-actions">
          <button 
            onClick={onClose}
            className="cancel-button"
          >
            Cancel
          </button>
          <button 
            onClick={handleSendProposal} 
            className="confirm-button"
            disabled={!completionDate || !logistics.trim()}
          >
            Confirm Exchange
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExchangeConfirmation;