// src/components/LandingPage.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardContent, CardTitle, CardDescription, CardFooter } from "./ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import TermsModal from './TermsModal';
import PrivacyPolicyModal from './PrivacyPolicyModal';

const LandingPage = () => {
  const [perks, setPerks] = useState([]);
  const [featuredPerks, setFeaturedPerks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef(null);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  useEffect(() => {
    const fetchPerks = async () => {
      try {
        const response = await fetch('https://perkexchange-production.up.railway.app/api/perks');
        const data = await response.json();
        const publicPerks = data.filter(perk => 
          !perk.userId.discoverability || perk.userId.discoverability === 'public'
        );
        setPerks(publicPerks);
        
        // Get featured/hero perks
        const heroPerks = publicPerks.filter(perk => perk.isHero);
        if (heroPerks.length > 0) {
          setFeaturedPerks(heroPerks.slice(0, 6)); // Increased to show more in carousel
        } else {
          // If no hero perks, get highest value perks
          const sortedPerks = [...publicPerks].sort((a, b) => b.estimatedValue - a.estimatedValue);
          setFeaturedPerks(sortedPerks.slice(0, 6)); // Increased to show more in carousel
        }
      } catch (error) {
        console.error('Error fetching perks:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPerks();
  }, []);

  // Auto-rotate carousel
  useEffect(() => {
    if (featuredPerks.length <= 1) return;
    
    const interval = setInterval(() => {
      setActiveIndex((current) => 
        current === featuredPerks.length - 1 ? 0 : current + 1
      );
    }, 5000);
    
    return () => clearInterval(interval);
  }, [featuredPerks.length]);

  const nextSlide = () => {
    if (featuredPerks.length <= 1) return;
    setActiveIndex((current) => 
      current === featuredPerks.length - 1 ? 0 : current + 1
    );
  };

  const prevSlide = () => {
    if (featuredPerks.length <= 1) return;
    setActiveIndex((current) => 
      current === 0 ? featuredPerks.length - 1 : current - 1
    );
  };

  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  const steps = [
    { 
      title: "1. Post Your Perk",
      description: "List your perks in seconds",
      icon: (
        <svg className="w-12 h-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
      )
    },
    { 
      title: "2. Receive Offers",
      description: "Connect with interested traders",
      icon: (
        <svg className="w-12 h-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
        </svg>
      )
    },
    { 
      title: "3. Swap & Enjoy",
      description: "Exchange perks seamlessly",
      icon: (
        <svg className="w-12 h-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
        </svg>
      )
    }
  ];

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div>
      {/* Login button in upper right corner - improved positioning for mobile */}
      {!isAuthenticated && (
        <div className="absolute top-4 right-4 sm:right-8 z-50">
          <Link
            to="/login"
            className="px-3 py-1.5 sm:px-4 sm:py-2 text-sm sm:text-base text-[#2A1DA9] bg-white rounded-lg border-2 border-[#2A1DA9] hover:bg-blue-50 transition-colors font-semibold"
          >
            Log In
          </Link>
        </div>
      )}
      
      {/* Hero Section with subtle gradient background - improved mobile padding */}
      <div className="relative bg-gradient-to-br from-blue-50/50 via-white to-white overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-300/5 to-white/10"></div>
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_left,rgba(59,130,246,0.05),transparent_600px)]"></div>
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_right,rgba(59,130,246,0.05),transparent_600px)]"></div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-b from-transparent to-white"></div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-12 pt-16 sm:pt-12 pb-16">
          <div className="flex flex-col items-center text-center mb-8 sm:mb-12">
            <img 
                src="/logo.png" 
                alt="Perk Exchange Logo" 
                className="w-full max-w-xs sm:max-w-sm md:max-w-2xl h-auto mb-6" 
            />
            <h1 className="text-lg sm:text-xl md:text-2xl font-medium text-gray-600 mb-6 sm:mb-8 max-w-3xl mx-auto px-2">
              Trade unused company perks for rewards you'll actually use
            </h1>
            {!isAuthenticated && (
              <div className="mb-6 sm:mb-10">
                <Link
                  to="/register"
                  className="inline-flex justify-center items-center px-6 sm:px-10 py-3 sm:py-4 text-base sm:text-lg font-semibold text-white bg-gradient-to-r from-[#2A1DA9] to-[#5A4FC9] rounded-lg transform transition-all hover:scale-105 hover:shadow-lg"
                >
                  Get Started Now
                </Link>
              </div>
            )}
          </div>
          
          {/* Visual Striking Carousel - responsive height adjustments */}
          <div className="relative overflow-hidden rounded-xl shadow-2xl max-w-6xl mx-auto mb-12 sm:mb-16" ref={carouselRef}>
            {featuredPerks.length > 0 ? (
              <>
                <div 
                  className="flex transition-transform duration-500 ease-in-out h-[22rem] sm:h-[26rem] md:h-[30rem]" 
                  style={{ transform: `translateX(-${activeIndex * 100}%)` }}
                >
                  {featuredPerks.map((perk, index) => (
                    <div key={perk._id} className="w-full flex-shrink-0 relative">
                      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/40 z-10"></div>
                      {perk.imageUrl ? (
                        <img 
                          src={perk.imageUrl} 
                          alt={perk.title} 
                          className="absolute w-full h-full object-cover"
                        />
                      ) : (
                        <div className="absolute w-full h-full bg-gradient-to-r from-[#2A1DA9] to-[#5A4FC9]"></div>
                      )}
                      
                      <div className="absolute inset-0 z-20 flex items-center justify-center p-4">
                        <div className="w-full max-w-xl sm:max-w-2xl px-4 sm:px-6 py-6 sm:py-8 bg-white/20 backdrop-blur-sm rounded-xl text-white shadow-lg">
                          <div className="flex flex-col sm:flex-row sm:items-start mb-3">
                            <div className="w-12 h-12 sm:w-16 sm:h-16 rounded-full bg-[#2A1DA9] flex items-center justify-center flex-shrink-0 mb-3 sm:mb-0 sm:mr-4 shadow-md mx-auto sm:mx-0">
                              <span className="text-xl sm:text-2xl font-bold">${perk.estimatedValue}</span>
                            </div>
                            <div className="text-center sm:text-left">
                              <h3 className="text-2xl sm:text-3xl font-bold mb-1 sm:mb-2">{perk.title}</h3>
                              <p className="text-base sm:text-lg text-white/90">{perk.userId.company || 'Company'}</p>
                            </div>
                          </div>
                          <p className="text-base sm:text-xl mb-4 sm:mb-6 line-clamp-3 sm:line-clamp-none">{perk.description}</p>
                          <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 items-center">
                            <div className="flex items-center gap-2 bg-white/20 py-1.5 sm:py-2 px-3 sm:px-4 rounded-full">
                              <span className="text-yellow-400 text-lg">★</span>
                              <span>{perk.userId.averageRating ? perk.userId.averageRating.toFixed(1) : '5.0'}</span>
                            </div>
                            <div className="flex-1 hidden sm:block"></div>
                            {!isAuthenticated ? (
                              <Link
                                to="/register"
                                className="w-full sm:w-auto inline-flex justify-center items-center px-4 sm:px-6 py-2 sm:py-3 font-semibold text-[#2A1DA9] bg-white rounded-xl transform transition-all hover:scale-105 hover:shadow-lg"
                              >
                                Trade For This Perk
                              </Link>
                            ) : (
                              <Link
                                to="/marketplace"
                                className="w-full sm:w-auto inline-flex justify-center items-center px-4 sm:px-6 py-2 sm:py-3 font-semibold text-[#2A1DA9] bg-white rounded-xl transform transition-all hover:scale-105 hover:shadow-lg"
                              >
                                View Details
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                
                {/* Navigation arrows - slightly smaller on mobile */}
                <button 
                  onClick={prevSlide}
                  className="absolute top-1/2 left-2 sm:left-4 -translate-y-1/2 bg-white/30 hover:bg-white/50 backdrop-blur-sm text-white rounded-full p-2 sm:p-3 z-30 transition-all hover:scale-110"
                  aria-label="Previous slide"
                >
                  <svg className="w-5 h-5 sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                </button>
                <button 
                  onClick={nextSlide}
                  className="absolute top-1/2 right-2 sm:right-4 -translate-y-1/2 bg-white/30 hover:bg-white/50 backdrop-blur-sm text-white rounded-full p-2 sm:p-3 z-30 transition-all hover:scale-110"
                  aria-label="Next slide"
                >
                  <svg className="w-5 h-5 sm:w-6 sm:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
                
                {/* Dots/indicators - adjusted for mobile */}
                <div className="absolute bottom-3 sm:bottom-6 left-0 right-0 flex justify-center gap-1.5 sm:gap-2 z-30">
                  {featuredPerks.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => goToSlide(index)}
                      className={`w-2.5 h-2.5 sm:w-3 sm:h-3 rounded-full transition-all ${
                        index === activeIndex 
                          ? 'bg-white w-6 sm:w-8' 
                          : 'bg-white/50 hover:bg-white/80'
                      }`}
                      aria-label={`Go to slide ${index + 1}`}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center h-64 sm:h-96 bg-gradient-to-r from-[#2A1DA9] to-[#5A4FC9] text-white">
                <div className="text-center p-6 sm:p-8">
                  <h3 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">Featured Perks Coming Soon</h3>
                  <p className="text-lg sm:text-xl">Be the first to list your company perks!</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {/* How It Works Section - Enhanced and mobile-optimized */}
      <div className="py-12 sm:py-20 max-w-7xl mx-auto px-4">
        <h2 className="text-2xl sm:text-3xl font-bold text-center mb-10 sm:mb-16 text-gray-900">How It Works</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 sm:gap-12">
          {steps.map((step, index) => (
            <div key={index} className="relative">
              {/* Connecting line between steps - hidden on mobile */}
              {index < steps.length - 1 && (
                <div className="absolute top-6 left-[calc(50%+2rem)] w-full h-0.5 bg-gradient-to-r from-blue-200 to-transparent hidden md:block"></div>
              )}
              <div className="flex flex-col items-center text-center group">
                <div className="w-20 h-20 sm:w-24 sm:h-24 rounded-full bg-blue-50 flex items-center justify-center mb-4 sm:mb-6 group-hover:bg-blue-100 transition-all duration-300">
                  <div className="w-12 h-12 sm:w-16 sm:h-16 text-blue-600 group-hover:text-blue-700 transition-colors duration-300">
                    {step.icon}
                  </div>
                </div>
                <h3 className="text-lg sm:text-xl font-bold text-gray-900 mb-2 sm:mb-3">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
        
        {/* Private Trading Info - adjusted padding for mobile */}
        <div className="mt-12 sm:mt-16 p-6 sm:p-8 bg-gray-50 rounded-xl max-w-3xl mx-auto">
          <div className="flex flex-col sm:flex-row sm:items-start">
            <div className="bg-blue-100 p-3 rounded-lg mb-4 sm:mb-0 sm:mr-4 mx-auto sm:mx-0">
              <svg className="w-8 h-8 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
              </svg>
            </div>
            <div className="text-center sm:text-left">
              <h3 className="text-xl font-bold text-gray-900 mb-2">Private Exchanges Available</h3>
              <p className="text-gray-600">
                Need more privacy? You can also exchange perks privately with your trusted connections. 
                Set your profile to private and only trade with friends you invite.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Featured Perks Section - responsive grid adjustments */}
      <div className="max-w-7xl mx-auto px-4 py-12 sm:py-16 border-t border-gray-100">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-8 sm:mb-10">More Available Perks</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
          {perks.slice(0, 6).map((perk) => (
            <Card key={perk._id} className="hover:shadow-xl transition-all duration-300 bg-white flex flex-col h-full">
              {perk.imageUrl && (
                <div className="h-40 sm:h-48 overflow-hidden rounded-t-lg">
                  <img src={perk.imageUrl} alt={perk.title} className="w-full h-full object-cover" />
                </div>
              )}
              <CardHeader className="sm:pb-3">
                <CardTitle className="text-lg sm:text-xl">{perk.title}</CardTitle>
                <CardDescription className="flex justify-between items-center">
                  <span className="text-gray-600 text-sm sm:text-base">{perk.userId.company || 'Company'}</span>
                  <span className="text-lg sm:text-xl font-bold text-[#2A1DA9]">${perk.estimatedValue}</span>
                </CardDescription>
              </CardHeader>
              
              <CardContent className="flex-grow pb-3">
                <p className="text-gray-600 mb-4 text-sm sm:text-base line-clamp-3">{perk.description}</p>
                <div className="flex items-center gap-2 text-xs sm:text-sm text-gray-500">
                  <span className="font-medium">Posted by: {perk.userId.username}</span>
                  {perk.userId.averageRating && (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <div className="flex items-center gap-1 cursor-help">
                            <span className="text-yellow-400">★</span>
                            <span>{perk.userId.averageRating.toFixed(1)}</span>
                            <span>({perk.userId.totalRatings})</span>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <div className="p-2">
                            <p className="font-medium mb-1">Exchange History</p>
                            <p className="text-sm">Successfully completed {perk.userId.totalRatings} exchanges</p>
                            <p className="text-sm text-gray-400">Average rating: {perk.userId.averageRating.toFixed(1)} / 5</p>
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </div>
              </CardContent>

              <CardFooter className="mt-auto">
                {!isAuthenticated ? (
                  <Link
                    to="/register"
                    className="w-full bg-gradient-to-r from-[#2A1DA9] to-[#5A4FC9] text-white py-2.5 sm:py-3 px-4 rounded-lg text-center font-medium hover:shadow-lg transition-all hover:scale-105 text-sm sm:text-base"
                  >
                    Sign Up to Trade
                  </Link>
                ) : (
                  <Link
                    to="/marketplace"
                    className="w-full bg-gradient-to-r from-[#2A1DA9] to-[#5A4FC9] text-white py-2.5 sm:py-3 px-4 rounded-lg text-center font-medium hover:shadow-lg transition-all hover:scale-105 text-sm sm:text-base"
                  >
                    View in Marketplace
                  </Link>
                )}
              </CardFooter>
            </Card>
          ))}
        </div>

        {perks.length === 0 && (
          <div className="text-center py-12 sm:py-16 bg-white rounded-lg shadow-sm">
            <h3 className="text-xl font-semibold text-gray-900 mb-3">No perks available</h3>
            <p className="text-gray-600">Check back later for new perks</p>
          </div>
        )}
        
        <div className="text-center mt-10 sm:mt-12">
          <Link
            to={isAuthenticated ? "/marketplace" : "/register"}
            className="inline-flex justify-center items-center px-6 sm:px-10 py-3 sm:py-4 text-base sm:text-lg font-semibold text-white bg-gradient-to-r from-[#2A1DA9] to-[#5A4FC9] rounded-lg hover:shadow-lg transition-all"
          >
            {isAuthenticated ? "View All Perks" : "Join and Start Trading"}
          </Link>
        </div>
      </div>
      
      {/* Footer with Terms and Privacy Policy links */}
      <div className="border-t border-gray-200 mt-12">
        <div className="max-w-7xl mx-auto px-4 py-6">
          <div className="flex flex-col sm:flex-row justify-center items-center gap-4 text-sm text-gray-500">
            <button 
              onClick={() => setIsTermsModalOpen(true)}
              className="hover:text-[#2A1DA9] transition-colors"
            >
              Terms & Conditions
            </button>
            <span className="hidden sm:inline">•</span>
            <button 
              onClick={() => setIsPrivacyModalOpen(true)}
              className="hover:text-[#2A1DA9] transition-colors"
            >
              Privacy Policy
            </button>
            <span className="hidden sm:inline">•</span>
            <span>© 2025 Perk Exchange. All rights reserved.</span>
          </div>
        </div>
      </div>
      
      {/* Modals */}
      <TermsModal 
        isOpen={isTermsModalOpen} 
        onClose={() => setIsTermsModalOpen(false)} 
        onAccept={() => setIsTermsModalOpen(false)}
        fullName="User"
      />
      
      <PrivacyPolicyModal 
        isOpen={isPrivacyModalOpen} 
        onClose={() => setIsPrivacyModalOpen(false)} 
      />
    </div>
  );
};

export default LandingPage;