import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFriends } from '../redux/slices/friendSlice';
import { Link, useNavigate } from 'react-router-dom';
import { showToast } from '../utils/toast';
import { fetchPerks } from '../redux/slices/perkSlice';
import { trackSignUp, trackBid, trackExchangeComplete } from '../utils/analytics';
import { createBid } from '../redux/slices/bidSlice';
import { 
  Card, 
  CardHeader, 
  CardContent, 
  CardFooter, 
  CardTitle, 
  CardDescription 
} from "./ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { 
  Gift, 
  TrendingUp, 
  Clock, 
  Star, 
  Zap, 
  Flame, 
  Tag 
} from 'lucide-react';

// Mock trades data
const MOCK_TRADES = [
  { id: 1, text: "🚀 Alex traded $50 Starbucks for ClassPass membership" },
  { id: 2, text: "🔥 Jenny accepted an offer for Nike shoes" },
  { id: 3, text: "💫 Mark exchanged DoorDash credits for Spotify Premium" }
];

const FilterPill = ({ active, children, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-full text-sm font-medium transition-all ${
      active 
        ? 'bg-blue-600 text-white shadow-md'
        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
    }`}
  >
    {children}
  </button>
);

const LiveTicker = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % MOCK_TRADES.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-gradient-to-r from-blue-50 to-white p-3 rounded-lg mb-6 overflow-hidden">
      <div className="flex items-center text-sm text-gray-600 animate-slide">
        <Zap className="w-4 h-4 text-blue-500 mr-2" />
        {MOCK_TRADES[currentIndex].text}
      </div>
    </div>
  );
};
const HeroBidForm = ({ perk, userPerks, onBid }) => {
  const [selectedPerk, setSelectedPerk] = useState('');
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (userPerks?.length === 1) {
      setSelectedPerk(userPerks[0]._id);
    }
  }, [userPerks]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onBid(perk._id, selectedPerk, quantity);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-2 w-full">
      {userPerks?.length === 1 ? (
        <div className="bg-white/10 p-2 rounded-lg text-sm text-white">
          Trading with: {userPerks[0].title}
        </div>
      ) : (
        <select
          value={selectedPerk}
          onChange={(e) => setSelectedPerk(e.target.value)}
          className="w-full p-2 border rounded-lg bg-white text-gray-800"
        >
          <option value="">Select your perk to offer</option>
          {userPerks?.map((userPerk) => (
            <option key={userPerk._id} value={userPerk._id}>
              {userPerk.title} (${userPerk.estimatedValue})
            </option>
          ))}
        </select>
      )}
      
      <button
        type="submit"
        disabled={!selectedPerk}
        className="w-full bg-white text-blue-600 font-medium py-3 rounded-lg hover:bg-blue-50 disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
      >
        {userPerks?.length === 1 ? 'Quick Bid' : 'Place Bid'}
      </button>
    </form>
  );
};
const HeroSection = ({ heroPerks, userPerks, onBid }) => {
  if (!heroPerks?.length) return null;
  
  // Sort by heroOrder if available
  const sortedHeroPerks = [...heroPerks].sort((a, b) => {
    if (a.heroOrder && b.heroOrder) return a.heroOrder - b.heroOrder;
    if (a.heroOrder) return -1;
    if (b.heroOrder) return 1;
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  
  const [mainPerk, ...sidePerks] = sortedHeroPerks;
  
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
  {/* Main featured perk - full bleed style */}
  <div className="md:col-span-2 rounded-2xl overflow-hidden shadow-lg relative min-h-[250px] md:aspect-[16/9]">
    {/* Full bleed image with gradient overlay */}
    <div className="absolute inset-0">
      {mainPerk.imageUrl ? (
        <>
          <img 
            src={mainPerk.imageUrl} 
            alt={mainPerk.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-40"></div>
        </>
      ) : (
        <div className="w-full h-full bg-gradient-to-r from-blue-600 to-blue-400"></div>
      )}
    </div>
    
    {/* Content section as overlay */}
    <div className="relative z-10 p-4 sm:p-6 flex flex-col h-full text-white">
      <h2 className="text-xl sm:text-2xl font-bold text-white mb-1 sm:mb-2">{mainPerk.title}</h2>
      <p className="text-white/90 text-sm sm:text-base mb-2 sm:mb-4">{mainPerk.description}</p>
      
      <div className="mt-auto flex flex-col sm:grid sm:grid-cols-2 gap-3 sm:gap-4 items-start sm:items-end">
        <div className="text-white text-2xl sm:text-3xl font-bold">${mainPerk.estimatedValue}</div>
        <div className="w-full">
          <HeroBidForm 
            perk={mainPerk}
            userPerks={userPerks}
            onBid={onBid}
          />
        </div>
      </div>
    </div>
  </div>
  
  {/* Sidebar featured perks */}
  <div className="flex flex-col justify-between gap-4 md:h-full">
    {sidePerks.slice(0, 2).map((perk, index) => (
      <div key={perk._id} className="bg-white rounded-2xl shadow-md flex overflow-hidden md:h-[calc(50%-8px)]">
        {perk.imageUrl ? (
          <div className="w-1/3 relative">
            <img 
              src={perk.imageUrl} 
              alt={perk.title}
              className="h-full w-full object-cover"
            />
            {perk.isHero && (
              <div className="absolute top-2 right-2 bg-yellow-400 text-white px-2 py-1 rounded-full text-xs flex items-center">
                <Star className="w-3 h-3 mr-1" />
                Featured
              </div>
            )}
          </div>
        ) : (
          <div className="w-1/3 bg-blue-100 flex items-center justify-center">
            <Gift className="w-10 h-10 text-blue-500" />
          </div>
        )}
        
        <div className="p-3 sm:p-4 flex flex-col justify-between w-2/3">
          <div>
            <h3 className="font-semibold text-gray-800 text-sm sm:text-base">{perk.title}</h3>
            <p className="text-xs sm:text-sm text-gray-600 line-clamp-2">{perk.description}</p>
          </div>
          
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 mt-2">
            <span className="font-bold text-blue-600">${perk.estimatedValue}</span>
            <button
              onClick={() => onBid(perk._id, userPerks[0]?._id, 1)}
              disabled={!userPerks?.length}
              className="w-full sm:w-auto px-3 py-1 bg-blue-600 text-white text-xs sm:text-sm rounded-full hover:bg-blue-700 transition-colors disabled:bg-gray-300"
            >
              Make Offer
            </button>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>
  );
};

const BidForm = ({ perk, userPerks, onBid }) => {
  const [selectedPerk, setSelectedPerk] = useState('');
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (userPerks?.length === 1) {
      setSelectedPerk(userPerks[0]._id);
    }
  }, [userPerks]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onBid(perk._id, selectedPerk, quantity);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      {userPerks?.length === 1 ? (
        <div className="p-2 bg-blue-50 rounded-lg text-sm text-blue-700">
          Trading with: {userPerks[0].title}
        </div>
      ) : (
        <select
          value={selectedPerk}
          onChange={(e) => setSelectedPerk(e.target.value)}
          className="w-full p-2 border rounded-lg"
        >
          <option value="">Select your perk to offer</option>
          {userPerks?.map((userPerk) => (
            <option key={userPerk._id} value={userPerk._id}>
              {userPerk.title} (${userPerk.estimatedValue})
            </option>
          ))}
        </select>
      )}
      
      {selectedPerk && (
        <input
          type="number"
          min="1"
          value={quantity}
          onChange={(e) => setQuantity(parseInt(e.target.value))}
          className="w-full p-2 border rounded-lg"
          placeholder="Quantity"
        />
      )}
      
      <button
        type="submit"
        disabled={!selectedPerk}
        className="w-full bg-gradient-to-r from-blue-600 to-blue-500 text-white py-2 px-4 rounded-lg hover:from-blue-700 hover:to-blue-600 transition-colors disabled:from-gray-400 disabled:to-gray-300 disabled:cursor-not-allowed transform hover:scale-[1.02] active:scale-[0.98] duration-200"
      >
        {userPerks?.length === 1 ? 'Quick Bid' : 'Place Bid'}
      </button>
    </form>
  );
};

const PerkCard = ({ perk, existingBid, userPerks, onBid, isCompact }) => {
  const [showBidForm, setShowBidForm] = useState(false);
  const isNew = new Date(perk.createdAt) > new Date(Date.now() - 86400000);
  const isPopular = perk.estimatedValue > 100;
  
  if (isCompact) {
    return (
      <div className="bg-white rounded-2xl shadow-md h-36 flex overflow-hidden">
        {perk.imageUrl ? (
          <div className="w-1/3 relative">
            <img 
              src={perk.imageUrl} 
              alt={perk.title}
              className="h-full w-full object-cover"
            />
          </div>
        ) : (
          <div className="w-1/3 bg-blue-100 flex items-center justify-center">
            <Gift className="w-10 h-10 text-blue-500" />
          </div>
        )}
        
        <div className="p-4 flex flex-col justify-between w-2/3">
          <div>
            <h3 className="font-semibold text-gray-800">{perk.title}</h3>
            <p className="text-sm text-gray-600 line-clamp-2">{perk.description}</p>
          </div>
          
          <div className="flex justify-between items-center mt-2">
            <span className="font-bold text-blue-600">${perk.estimatedValue}</span>
            <button
              onClick={() => onBid(perk._id, userPerks[0]?._id, 1)}
              disabled={!userPerks?.length}
              className="px-3 py-1 bg-blue-600 text-white text-sm rounded-full hover:bg-blue-700 transition-colors disabled:bg-gray-300"
            >
              Make Offer
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <Card className="group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
      {perk.imageUrl && (
        <div className="relative w-full h-48">
          <img 
            src={perk.imageUrl} 
            alt={perk.title}
            className="w-full h-full object-cover rounded-t-lg"
          />
          {perk.isHero && (
            <div className="absolute top-2 right-2 bg-yellow-400 text-white px-2 py-1 rounded-full flex items-center">
              <Star className="w-4 h-4 mr-1" />
              Featured
            </div>
          )}
        </div>
      )}
      <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-2">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Gift className="w-6 h-6 text-blue-500" />
          </div>
          <div>
            <CardTitle className="text-lg font-semibold">
              {perk.title}
            </CardTitle>
          <CardDescription className="flex flex-wrap items-center">
            <Link to={`/profile/${perk.userId?._id}`} className="hover:underline hover:text-blue-600">
              from {perk.userId?.username || 'Unknown User'}
            </Link>
            {perk.userId && perk.userId.totalRatings > 0 && (
              <span className="ml-2 flex items-center">
                <Star className="w-3 h-3 text-yellow-400 mr-1" />
                <span>{parseFloat(perk.userId.averageRating || 0).toFixed(1)}</span>
                <span className="text-gray-500 text-xs ml-1">({perk.userId.totalRatings})</span>
              </span>
            )}
            {perk.isHero && !perk.imageUrl && (
              <span className="ml-2 text-yellow-600 flex items-center">
                <Star className="w-4 h-4 mr-1" />
                Featured
              </span>
            )}
          </CardDescription>
          </div>
        </div>
        <div className="text-xl font-bold text-blue-600">
          ${perk.estimatedValue}
        </div>
      </CardHeader>

      <CardContent>
        <p className="text-gray-600 text-sm mb-3">{perk.description}</p>
        
        <div className="flex flex-wrap gap-2 mt-2">
          {isNew && (
            <span className="flex items-center text-xs px-2 py-1 bg-green-100 text-green-800 rounded-full">
              <Flame className="w-3 h-3 mr-1" />
              New
            </span>
          )}
          
          {isPopular && (
            <span className="flex items-center text-xs px-2 py-1 bg-purple-100 text-purple-800 rounded-full">
              <TrendingUp className="w-3 h-3 mr-1" />
              Popular
            </span>
          )}
          
          <span className="flex items-center text-xs px-2 py-1 bg-blue-100 text-blue-800 rounded-full">
            <Tag className="w-3 h-3 mr-1" />
            {perk.userId?.company || 'Unknown'}
          </span>
        </div>
      </CardContent>

      <CardFooter className="justify-between items-center pt-1">
  <div></div>
  
  {existingBid ? (
    <span className="text-sm text-green-600 font-medium flex items-center">
      <Clock className="w-4 h-4 mr-1" />
      Bid Pending
    </span>
  ) : (
    <>
      {showBidForm ? (
        <BidForm 
          perk={perk}
          userPerks={userPerks}
          onBid={onBid}
        />
      ) : (
        <button
          onClick={() => setShowBidForm(true)}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Make Offer
        </button>
      )}
    </>
  )}
</CardFooter>
    </Card>
  );
};

const Marketplace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState('recent');

  // Redux selectors with null checks
  const perks = useSelector((state) => state.perks.items) || [];
  const bids = useSelector((state) => state.bids.items) || [];
  const currentUser = useSelector((state) => state.auth.user);
  const currentUserId = currentUser?._id;
  const friends = useSelector((state) => state.friends.friends) || [];
  const loading = useSelector((state) => state.perks.loading);

  // Define userPerks properly with null checks
  const userPerks = useMemo(() => {
    if (!perks || !currentUserId) return [];
    return perks.filter(perk => 
      perk?.userId?._id === currentUserId
    );
  }, [perks, currentUserId]);

  useEffect(() => {
    if (!currentUserId) {
      navigate('/login');
      return;
    }

    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(fetchPerks()),
          dispatch(getFriends())
        ]);
      } catch (error) {
        console.error('Error fetching marketplace data:', error);
        showToast.error('Failed to load marketplace data');
      }
    };

    fetchData();
  }, [dispatch, currentUserId, navigate]);

  const hasExistingBid = (marketPerkId) => {
    if (!bids || !marketPerkId) return false;
    return bids.some(bid => 
      bid?.offeredPerkId === marketPerkId && 
      bid?.status === 'pending'
    );
  };

  const { heroPerks, regularPerks } = useMemo(() => {
    if (!perks || !currentUserId || !friends) return { heroPerks: [], regularPerks: [] };

    const filteredPerks = perks.filter(perk => {
      if (!perk?.userId?._id) return false;

      const isOwnPerk = perk.userId._id === currentUserId;
      const isPublic = !perk.userId.discoverability || perk.userId.discoverability === 'public';
      const isFriend = friends.some(friend => {
        if (!friend?.requesterId?._id || !friend?.recipientId?._id) return false;
        return friend.requesterId._id === perk.userId._id || 
              friend.recipientId._id === perk.userId._id;
      });
      
      return !isOwnPerk && (isPublic || isFriend);
    });

    const sortedPerks = [...filteredPerks].sort((a, b) => {
      switch (sortBy) {
        case 'value-high':
          return b.estimatedValue - a.estimatedValue;
        case 'value-low':
          return a.estimatedValue - b.estimatedValue;
        case 'company':
          return (a.userId?.company || '').localeCompare(b.userId?.company || '');
        case 'recent':
        default:
          return new Date(b.createdAt) - new Date(a.createdAt);
      }
    });

    return {
      heroPerks: sortedPerks.filter(perk => perk.isHero),
      regularPerks: sortedPerks.filter(perk => !perk.isHero)
    };
  }, [perks, currentUserId, friends, sortBy]);

  const handleBid = async (marketPerkId, userPerkId, quantity) => {
    if (!currentUserId) {
      navigate('/login');
      return;
    }

    try {
      await showToast.promise(
        dispatch(createBid({
          offeredPerkId: marketPerkId,
          bidPerkId: userPerkId,
          quantity
        })).unwrap(),
        {
          loading: 'Placing your bid...',
          success: 'Bid placed successfully!',
          error: 'Failed to place bid. Please try again.'
        }
      );

    // Track bid placement
    trackBid();

    } catch (error) {
      console.error('Error in handleBid:', error);
    }
  };

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 text-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
      </div>
    );
  }

  if (!currentUserId) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 text-center">
        <p className="text-gray-600 mb-4">Please log in to view the marketplace</p>
        <Link
          to="/login"
          className="inline-block px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Log In
        </Link>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <LiveTicker />
      
      {/* Hero Section */}
      {heroPerks.length > 0 && (
        <HeroSection 
          heroPerks={heroPerks} 
          userPerks={userPerks} 
          onBid={handleBid} 
        />
      )}

      {/* Regular Perks Section */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8 gap-4">
        <h2 className="text-3xl font-bold text-gray-900">Available Perks</h2>
        <div className="flex flex-wrap gap-2">
          <FilterPill 
            active={sortBy === 'recent'} 
            onClick={() => setSortBy('recent')}
          >
            Recent
          </FilterPill>
          <FilterPill 
            active={sortBy === 'value-high'} 
            onClick={() => setSortBy('value-high')}
          >
            Value: High to Low
          </FilterPill>
          <FilterPill 
            active={sortBy === 'value-low'} 
            onClick={() => setSortBy('value-low')}
          >
            Value: Low to High
          </FilterPill>
          <FilterPill 
            active={sortBy === 'company'} 
            onClick={() => setSortBy('company')}
          >
            Company
          </FilterPill>
        </div>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {regularPerks.map((perk) => (
          <PerkCard
            key={perk._id}
            perk={perk}
            existingBid={hasExistingBid(perk._id)}
            userPerks={userPerks}
            onBid={handleBid}
          />
        ))}
      </div>
      
      {regularPerks.length === 0 && (
        <div className="text-center py-16">
          <Gift className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <h3 className="text-xl font-medium text-gray-900">No perks available</h3>
          <p className="text-gray-600 mt-2">
            Check back soon or invite your friends to share their perks!
          </p>
        </div>
      )}
    </div>
  );
};

export default Marketplace;