import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

export const fetchBids = createAsyncThunk(
  'bids/fetchBids',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/bids');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch bids');
    }
  }
);

export const createBid = createAsyncThunk(
  'bids/createBid',
  async (bidData, { rejectWithValue }) => {
    try {
      console.log('Creating bid with data:', bidData);
      const response = await api.post('/bids', bidData);
      console.log('Bid creation response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in createBid:', error.response?.data);
      return rejectWithValue(error.response?.data?.message || 'Failed to create bid');
    }
  }
);

export const updateBid = createAsyncThunk(
  'bids/updateBid',
  async ({ bidId, quantity }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/bids/${bidId}`, { quantity });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update bid');
    }
  }
);

export const deleteBid = createAsyncThunk(
  'bids/deleteBid',
  async (bidId, { rejectWithValue }) => {
    try {
      await api.delete(`/bids/${bidId}`);
      return bidId;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete bid');
    }
  }
);

export const acceptBid = createAsyncThunk(
  'bids/acceptBid',
  async ({ bidId, exchangeData }, { rejectWithValue, dispatch }) => {
    try {
      console.log('Accepting bid:', bidId);
      console.log('Exchange data:', exchangeData);
      
      // Send both bid acceptance and exchange data
      const response = await api.post(`/bids/${bidId}/accept`, exchangeData);
      console.log('Bid accepted response:', response.data);
      
      // Dispatch action to add the new exchange to the exchanges slice
      if (response.data.exchange) {
        dispatch({ 
          type: 'exchanges/addExchange',
          payload: response.data.exchange
        });
      }
      
      return response.data.bid;
    } catch (error) {
      console.error('Error accepting bid:', error);
      return rejectWithValue(error.response?.data?.message || 'Failed to accept bid');
    }
  }
);

export const rejectBid = createAsyncThunk(
  'bids/rejectBid',
  async (bidId, { rejectWithValue }) => {
    try {
      const response = await api.post(`/bids/${bidId}/reject`);
      return response.data.bid;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to reject bid');
    }
  }
);

const bidSlice = createSlice({
  name: 'bids',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBids.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBids.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchBids.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createBid.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBid.fulfilled, (state, action) => {
        state.loading = false;
        state.items.push(action.payload);
      })
      .addCase(createBid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateBid.fulfilled, (state, action) => {
        const index = state.items.findIndex(bid => bid._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(deleteBid.fulfilled, (state, action) => {
        state.items = state.items.filter(bid => bid._id !== action.payload);
      })
      .addCase(acceptBid.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(acceptBid.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.items.findIndex(bid => bid._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(acceptBid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(rejectBid.fulfilled, (state, action) => {
        const index = state.items.findIndex(bid => bid._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      });
  },
});

export default bidSlice.reducer;