import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFriends, getPendingRequests, respondToRequest, removeFriend, sendFriendRequest } from '../redux/slices/friendSlice';
import { showToast } from '../utils/toast';
import api from '../utils/api';
import { 
  Card, 
  CardHeader, 
  CardContent, 
  CardTitle,
  CardDescription,
  CardFooter 
} from "./ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { 
  Users, 
  UserPlus, 
  Search, 
  Check, 
  X, 
  Star,
  Building2,
  Mail,
  AtSign
} from 'lucide-react';

const InviteCodeSearch = ({ onSearch }) => {
  const [inviteCode, setInviteCode] = useState('');
  const [searching, setSearching] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inviteCode.trim()) return;

    setSearching(true);
    try {
      const response = await api.get(`/users/invite/${inviteCode.trim()}`);
      onSearch(response.data);
    } catch (error) {
      showToast.error(error.response?.data?.message || 'User not found');
      onSearch(null);
    } finally {
      setSearching(false);
    }
  };

  return (
    <Card className="mb-8">
      <CardHeader>
        <CardTitle>Find by Invite Code</CardTitle>
        <CardDescription>
          Enter an invite code to connect with a private profile
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="flex gap-2">
          <input
            type="text"
            value={inviteCode}
            onChange={(e) => setInviteCode(e.target.value)}
            placeholder="Enter invite code"
            className="flex-1 px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <button
            type="submit"
            disabled={searching || !inviteCode.trim()}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-blue-300"
          >
            <Search className="w-4 h-4 mr-2" />
            {searching ? 'Searching...' : 'Search'}
          </button>
        </form>
      </CardContent>
    </Card>
  );
};

// New component for username search
const UsernameSearch = ({ onSearch }) => {
  const [username, setUsername] = useState('');
  const [searching, setSearching] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username.trim() || username.trim().length < 3) {
      showToast.error('Username must be at least 3 characters');
      return;
    }

    setSearching(true);
    try {
      const response = await api.get(`/users/username/${username.trim()}`);
      onSearch(response.data);
    } catch (error) {
      showToast.error(error.response?.data?.message || 'No users found');
      onSearch(null);
    } finally {
      setSearching(false);
    }
  };

  return (
    <Card className="mb-8">
      <CardHeader>
        <CardTitle>Find by Username</CardTitle>
        <CardDescription>
          Search for users by their username (public profiles only)
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="flex gap-2">
          <div className="flex-1 relative">
            <AtSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter username"
              className="w-full pl-10 pr-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <button
            type="submit"
            disabled={searching || !username.trim() || username.trim().length < 3}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-blue-300"
          >
            <Search className="w-4 h-4 mr-2" />
            {searching ? 'Searching...' : 'Search'}
          </button>
        </form>
      </CardContent>
    </Card>
  );
};

const FriendCard = ({ friend, onRemove }) => {
  const currentUser = useSelector(state => state.auth.user);
  
  // Determine which user is the friend (not the current user)
  const otherUser = friend.requesterId._id.toString() === currentUser._id.toString()
    ? friend.recipientId
    : friend.requesterId;

  return (
    <Card className="group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
      <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-2">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Users className="w-6 h-6 text-blue-500" />
          </div>
          <div>
            <CardTitle className="text-lg font-semibold">
              {otherUser.username}
            </CardTitle>
            <CardDescription className="flex items-center gap-2">
              <Building2 className="w-4 h-4" />
              {otherUser.company}
            </CardDescription>
          </div>
        </div>
        {otherUser.averageRating && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className="flex items-center gap-1 cursor-help">
                  <Star className="w-4 h-4 text-yellow-400" />
                  <span>{otherUser.averageRating.toFixed(1)}</span>
                  <span className="text-gray-500">
                    ({otherUser.totalRatings})
                  </span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <div className="p-2">
                  <p className="font-medium mb-1">Exchange History</p>
                  <p className="text-sm">Successfully completed {otherUser.totalRatings} exchanges</p>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </CardHeader>

      <CardFooter className="justify-end space-x-2">
        <Link 
          to={`/profile/${otherUser._id}`}
          className="flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          <Users className="w-4 h-4 mr-2" />
          View Profile
        </Link>
        <button
          onClick={() => onRemove(otherUser._id)}
          className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
        >
          <X className="w-4 h-4 mr-2" />
          Remove
        </button>
      </CardFooter>
    </Card>
  );
};

const RequestCard = ({ request, onAccept, onDecline }) => (
  <Card className="group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
    <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-2">
      <div className="flex items-center space-x-3">
        <div className="p-2 bg-blue-50 rounded-lg">
          <UserPlus className="w-6 h-6 text-blue-500" />
        </div>
        <div>
          <CardTitle className="text-lg font-semibold">
            {request.requesterId.username}
          </CardTitle>
          <CardDescription className="flex items-center gap-2">
            <Building2 className="w-4 h-4" />
            {request.requesterId.company}
          </CardDescription>
        </div>
      </div>
    </CardHeader>

    <CardFooter className="justify-end space-x-2">
      <button
        onClick={() => onAccept(request._id)}
        className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
      >
        <Check className="w-4 h-4 mr-2" />
        Accept
      </button>
      <button
        onClick={() => onDecline(request._id)}
        className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
      >
        <X className="w-4 h-4 mr-2" />
        Decline
      </button>
    </CardFooter>
  </Card>
);

const EmptyState = () => (
  <div className="text-center py-12 bg-gray-50 rounded-lg">
    <Users className="w-12 h-12 text-gray-400 mx-auto mb-4" />
    <h3 className="text-lg font-medium text-gray-900 mb-2">No friends yet</h3>
    <p className="text-gray-600 mb-4">
      Find other users by browsing the marketplace or using invite codes
    </p>
  </div>
);

const FriendsList = () => {
  const dispatch = useDispatch();
  const [showPending, setShowPending] = useState(false);
  const [searchedUser, setSearchedUser] = useState(null);
  const [searchType, setSearchType] = useState('invite'); // 'invite' or 'username'

  const { friends, pendingRequests, loading } = useSelector((state) => state.friends);

  useEffect(() => {
    const fetchData = () => {
      dispatch(getFriends());
      dispatch(getPendingRequests());
    };

    fetchData();
    const interval = setInterval(fetchData, 30000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const handleRespondToRequest = async (requestId, accept) => {
    try {
      await showToast.promise(
        dispatch(respondToRequest({ requestId, accept })).unwrap(),
        {
          loading: accept ? 'Accepting request...' : 'Declining request...',
          success: accept ? 'Friend request accepted!' : 'Friend request declined',
          error: 'Failed to process request'
        }
      );
    } catch (error) {
      console.error('Error responding to friend request:', error);
    }
  };

  const handleRemoveFriend = async (friendId) => {
    if (window.confirm('Are you sure you want to remove this friend?')) {
      try {
        await showToast.promise(
          dispatch(removeFriend(friendId)).unwrap(),
          {
            loading: 'Removing friend...',
            success: 'Friend removed',
            error: 'Failed to remove friend'
          }
        );
      } catch (error) {
        console.error('Error removing friend:', error);
      }
    }
  };

  const handleSendFriendRequest = async (userId) => {
    try {
      await showToast.promise(
        dispatch(sendFriendRequest(userId)).unwrap(),
        {
          loading: 'Sending friend request...',
          success: 'Friend request sent successfully!',
          error: 'Failed to send friend request'
        }
      );
      setSearchedUser(null);
    } catch (error) {
      console.error('Error sending friend request:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-3xl font-bold text-gray-900">My Friends</h2>
        {pendingRequests.length > 0 && (
          <button
            onClick={() => setShowPending(!showPending)}
            className="flex items-center gap-2 px-4 py-2 bg-blue-100 text-blue-700 rounded-md hover:bg-blue-200"
          >
            {showPending ? (
              <>
                <Users className="w-4 h-4" />
                View Friends
              </>
            ) : (
              <>
                <Mail className="w-4 h-4" />
                View Requests
                <span className="flex h-5 w-5 items-center justify-center text-xs bg-blue-600 text-white rounded-full">
                  {pendingRequests.length}
                </span>
              </>
            )}
          </button>
        )}
      </div>

      {!showPending && (
        <>
          <div className="flex mb-4 gap-4">
            <button
              onClick={() => setSearchType('invite')}
              className={`flex items-center px-4 py-2 rounded-md ${
                searchType === 'invite' ? 'bg-blue-600 text-white' : 'bg-gray-200 hover:bg-gray-300'
              }`}
            >
              <Search className="w-4 h-4 mr-2" />
              Search by Invite Code
            </button>
            <button
              onClick={() => setSearchType('username')}
              className={`flex items-center px-4 py-2 rounded-md ${
                searchType === 'username' ? 'bg-blue-600 text-white' : 'bg-gray-200 hover:bg-gray-300'
              }`}
            >
              <AtSign className="w-4 h-4 mr-2" />
              Search by Username
            </button>
          </div>
          
          {searchType === 'invite' ? (
            <InviteCodeSearch onSearch={setSearchedUser} />
          ) : (
            <UsernameSearch onSearch={setSearchedUser} />
          )}
          
          {searchedUser && (
            <div className="mb-8">
              {Array.isArray(searchedUser) ? (
                // Display multiple users from username search
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {searchedUser.map(user => (
                    <Card key={user._id} className="hover:shadow-md transition-all">
                      <CardContent className="pt-6">
                        <div className="flex items-center justify-between">
                          <div>
                            <h3 className="font-medium">{user.username}</h3>
                            <p className="text-sm text-gray-600">{user.company}</p>
                            {user.averageRating && (
                              <div className="flex items-center gap-1 mt-1">
                                <Star className="w-4 h-4 text-yellow-400" />
                                <span>{user.averageRating.toFixed(1)}</span>
                                <span className="text-gray-500">({user.totalRatings} ratings)</span>
                              </div>
                            )}
                          </div>
                          <button
                            onClick={() => handleSendFriendRequest(user._id)}
                            className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                          >
                            <UserPlus className="w-4 h-4 mr-2" />
                            Add
                          </button>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              ) : (
                // Display single user from invite code search
                <Card className="mb-8">
                  <CardContent className="pt-6">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium">{searchedUser.username}</h3>
                        <p className="text-sm text-gray-600">{searchedUser.company}</p>
                        {searchedUser.averageRating && (
                          <div className="flex items-center gap-1 mt-1">
                            <Star className="w-4 h-4 text-yellow-400" />
                            <span>{searchedUser.averageRating.toFixed(1)}</span>
                            <span className="text-gray-500">({searchedUser.totalRatings} ratings)</span>
                          </div>
                        )}
                      </div>
                      <button
                        onClick={() => handleSendFriendRequest(searchedUser._id)}
                        className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                      >
                        <UserPlus className="w-4 h-4 mr-2" />
                        Send Friend Request
                      </button>
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
          )}
        </>
      )}

      {showPending ? (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {pendingRequests.map(request => (
            <RequestCard
              key={request._id}
              request={request}
              onAccept={(id) => handleRespondToRequest(id, true)}
              onDecline={(id) => handleRespondToRequest(id, false)}
            />
          ))}
        </div>
      ) : (
        friends.length > 0 ? (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {friends.map(friend => (
              <FriendCard
                key={friend._id}
                friend={friend}
                onRemove={handleRemoveFriend}
              />
            ))}
          </div>
        ) : (
          <EmptyState />
        )
      )}
    </div>
  );
};

export default FriendsList;